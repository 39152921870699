// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/primitives/Popover.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/primitives/Popover.tsx");
  import.meta.hot.lastModified = "1724328544937.2334";
}
// REMIX HMR END

"use client";
import { ChevronDownIcon, EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import * as React from "react";
import { cn } from "~/utils/cn";
import { LinkButton } from "./Buttons";
import { Paragraph } from "./Paragraph";
const Popover = PopoverPrimitive.Root;
const PopoverTrigger = PopoverPrimitive.Trigger;
const PopoverContent = React.forwardRef(_c = ({
  className,
  align = "center",
  sideOffset = 4,
  ...props
}, ref) => <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content ref={ref} align={align} sideOffset={sideOffset} avoidCollisions={true} className={cn("z-50 min-w-max rounded border border-charcoal-700 bg-background-bright p-4 shadow-md outline-none animate-in data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2", className)} style={{
    maxHeight: "var(--radix-popover-content-available-height)"
  }} {...props} />
  </PopoverPrimitive.Portal>);
_c2 = PopoverContent;
PopoverContent.displayName = PopoverPrimitive.Content.displayName;
function PopoverSectionHeader({
  title,
  variant = "extra-extra-small/dimmed/caps"
}) {
  return <Paragraph variant={variant} className="bg-charcoal-750 px-2.5 py-1.5">
      {title}
    </Paragraph>;
}
_c3 = PopoverSectionHeader;
function PopoverMenuItem({
  to,
  icon,
  title,
  isSelected,
  variant = {
    variant: "small-menu-item"
  },
  leadingIconClassName
}) {
  return <LinkButton to={to} variant={variant.variant} LeadingIcon={icon} leadingIconClassName={leadingIconClassName} fullWidth textAlignLeft TrailingIcon={isSelected ? "check" : undefined} className={cn("group-hover:bg-charcoal-700", isSelected ? "bg-charcoal-750 group-hover:bg-charcoal-600/50" : undefined)}>
      {title}
    </LinkButton>;
}
_c4 = PopoverMenuItem;
function PopoverCustomTrigger({
  isOpen,
  children,
  className,
  ...props
}) {
  return <PopoverTrigger {...props} className={cn("group flex items-center justify-end gap-1 rounded text-text-dimmed transition hover:bg-charcoal-850 hover:text-text-bright", className)}>
      {children}
    </PopoverTrigger>;
}
_c5 = PopoverCustomTrigger;
function PopoverArrowTrigger({
  isOpen,
  children,
  fullWidth = false,
  overflowHidden = false,
  className,
  ...props
}) {
  return <PopoverTrigger {...props} className={cn("group flex h-6 items-center gap-1 rounded px-2 text-text-dimmed transition hover:bg-charcoal-700 hover:text-text-bright", fullWidth && "w-full justify-between", className)}>
      <Paragraph variant="extra-small" className={cn("flex transition group-hover:text-text-bright", overflowHidden && "overflow-hidden")}>
        {children}
      </Paragraph>
      <ChevronDownIcon className={cn("h-3 w-3 min-w-[0.75rem] text-charcoal-600 transition group-hover:text-text-bright", isOpen && "-rotate-180")} />
    </PopoverTrigger>;
}
_c6 = PopoverArrowTrigger;
function PopoverVerticalEllipseTrigger({
  isOpen,
  className,
  ...props
}) {
  return <PopoverTrigger {...props} className={cn("group flex items-center justify-end gap-1 rounded px-1.5 py-1.5 text-text-dimmed transition hover:bg-charcoal-750 hover:text-text-bright", className)}>
      <EllipsisVerticalIcon className={cn("h-5 w-5 transition group-hover:text-text-bright")} />
    </PopoverTrigger>;
}
_c7 = PopoverVerticalEllipseTrigger;
export { Popover, PopoverArrowTrigger, PopoverContent, PopoverCustomTrigger, PopoverMenuItem, PopoverSectionHeader, PopoverTrigger, PopoverVerticalEllipseTrigger };
var _c, _c2, _c3, _c4, _c5, _c6, _c7;
$RefreshReg$(_c, "PopoverContent$React.forwardRef");
$RefreshReg$(_c2, "PopoverContent");
$RefreshReg$(_c3, "PopoverSectionHeader");
$RefreshReg$(_c4, "PopoverMenuItem");
$RefreshReg$(_c5, "PopoverCustomTrigger");
$RefreshReg$(_c6, "PopoverArrowTrigger");
$RefreshReg$(_c7, "PopoverVerticalEllipseTrigger");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;